import { Box, Divider, Grid, IconButton, Toolbar } from "@mui/material";
import {
    DataGridPro,
    GridActionsCellItem,
    GridAddIcon,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    frFR,
    nlNL,
    deDE,
    esES,
    ptBR,
    enUS,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridHeaderCheckbox,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    DeleteAction,
    PromptDialog,
    SearchInTable,
    defaultGridConfig,
} from "../DataGrid/cst_DataGrid";
import {
    fetchDataGrid,
    addNewModel,
    editModel,
    deleteModel,
    fetchData,
} from "../../js/utils/backend";
import { t } from "react-i18nify";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { BasicTooltip } from "./StyledComponents";
import { BasicButton, ButtonToolbar } from "./Custom Inputs/Buttons";
import { ScreenLoading } from "./CustomLoadings";
import { InProgress } from "./CustomSurfaces";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isArrayLength, isEmpty } from "../../js/utils/genericMethods";
import {
    isFetching,
    updateHighlightedRows,
    updateSpecificNodeEntityTree,
} from "../../js/redux/actions/userPref";
import { updateSearchText } from "../../js/redux/actions/searchFilter";
import { useUpdateEffect } from "../../js/utils/customHooks";
import { uuidv4 } from "../../js/redux/actions/syncTab";
import Axios from "axios";

export const conditionalEditDisabling = row => {
    return row?.State === "Removed";
};

export const rowDisableDelete = (row, userID, idToCompare = "OTUserID") => {
    if (row) {
        return row[idToCompare] === userID;
    }
};

const DataGridComponent = ({
    apiRef,
    baseURL,
    cellFocusField,
    gridSpecificColumns,
    hint,
    specificActionsColumns = _params => [],
    conditionalDeleteDisabling = rowDisableDelete,
    initialState,
    label,
    paramURL = "",
    children,
    forceGridRefresh,
    isEditDisabled,
    isDeleteDisabled = false,
    isActionDisabled,
    isCellEditable,
    toolbar = {},
    editingRights,
    visitedRow,
    disableTitle = false,
    disableResearch = false,
    isStandalone = true,
    onSelectionModelChange,
    inProgress = false,
    displaySelectAllCheckbox,
    GenericGridType,
    currentGenericGridType,
    specificScreenLoading,
    gridConfig = {},
    setGridConfig = () => {
        /* GenericDataGrid : if we go back (via button), the gridConfig will be cleared, and the new fetched grid will provide new configuration */
    },
    components = {},
    setExcelRequest = () => {
        /* GenericDataGrid:  The exact request is sent to parent component, that contains for example the button to export excel (that should have identically the same params as the datagrid). */
    },
    onDelete = () => {
        /* called after delete completion */
    },
    withBackButton = false,
    boxStyle = {},
    hideActionColumn = false,
    isAdvancedSelectorGrid = false,
    bodyOfRequest = false,
    reportingRecords,
    doOnPageChange = () => {
        return;
    },
    rowSelectability = () => true,
    beforeCommitRow = row => row,
    rowHighlightPropName = "ID",
    requestUrl = "",
    ...props
}) => {
    const mainUrl = requestUrl=== "" ? baseURL : baseURL+"/"+requestUrl;
    const allUsersLabels = ["All Users", "Tous les utilisateurs"];
    let filters = useSelector(state => state.searchFilter.Filters);
    const location = useLocation();
    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const token = useSelector(state => state.auth.Token);
    const userID = useSelector(state => state.auth.UserID);
    const [isLoading, setIsLoading] = useState(true);
    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const [requestsStartedCount, setRequestsStartedCount] = useState(0);
    const [requestsFinalized, setRequestsFinalized] = useState([]);
    const [editing, setEditing] = useState(false);
    const [rows, setRows] = useState();
    const { element, fileType } = useParams();

    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState(
        filters.filter(d => d.BaseUrl === mainUrl)[0]
        ? filters.filter(d => d.BaseUrl === mainUrl)[0].SearchText : ""
    );

    useEffect(() => {
        if(filters.filter(d => d.BaseUrl===mainUrl)[0]){
            filters.filter(d => d.BaseUrl===mainUrl)[0].SearchText = searchText
            dispatch(updateSearchText(filters))
        }
    }, [searchText, mainUrl, filters, dispatch]);

    const [pageSize, setPageSize] = React.useState(
        defaultGridConfig.query.pageSize
    );
    /** Custom state to increment the rows per page display when users add a new row */
    const [incrementedRow, setIncrementedRow] = useState(null);
    const { HighlightedRows } = useSelector(state => state.userPref);

    const [rowCount, setRowCount] = useState(
        defaultGridConfig?.query?.pageSize
    );
    const [page, setPage] = useState(defaultGridConfig.query.pageNumber || 0);
    const [sortModel, setSortModel] = useState(
        initialState?.sorting?.sortModel
    );
    const toolbarDefault = {
        add: allUsersLabels.includes(label) ? false : (editingRights ?? (CompanyManager || HoldingManager)),
        newFilter: null,
        newAction: null,
        toolbarFilteringToolsOnly: false,
        toolbarActionOnly: false,
        disableToolbar: false,
        tools: {
            disableColumnFilter: true,
            disableColumnSelector: true,
            disableDensitySelector: true,
            disableExport: true,
        },
    };
    const toolbarManagement = {
        ...toolbarDefault,
        ...toolbar,
        tools: { ...toolbarDefault.tools, ...toolbar?.tools },
    };
    const locale = useSelector(state => state.i18n.locale);
    const dataGridLocalisation = {
        ENG: enUS,
        FRA: frFR,
        NLD: nlNL,
        DEU: deDE,
        SPA: esES,
        POR: ptBR,
    };
    const history = useHistory();

    /** Default actions for each grid, which can be disabled and/or replace by another ones (see: specificActionsColumns). */
    const editActions = params => {
        const isInEditMode = apiRef?.current?.getRowMode(params.id) === "edit";
        if (editingRights ?? (CompanyManager || HoldingManager)) {
            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<CheckIcon />}
                        label="Save"
                        onClick={handleSaveClick(params.id)}
                        color="primary"
                        disabled={isLocalLoading}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(params.id)}
                        color="inherit"
                        disabled={isLocalLoading}
                    />,
                ];
            }
            return [
                <>
                    {isEditDisabled ||
                    conditionalEditDisabling(params.row) ? null : (
                        <GridActionsCellItem
                            icon={
                                <BasicTooltip title={t("common.Edit")}>
                                    <EditOutlinedIcon />
                                </BasicTooltip>
                            }
                            label={t("common.Edit")}
                            className="textPrimary"
                            onClick={handleEditClick(params.id)}
                            color="inherit"
                            disabled={editing || isLocalLoading}
                        />
                    )}
                    {isDeleteDisabled ||
                    conditionalDeleteDisabling(params.row, userID) ? null : (
                        <DeleteAction
                            icon={
                                <BasicTooltip title={t("common.Delete")}>
                                    <DeleteIcon />
                                </BasicTooltip>
                            }
                            deleteAction={async () => {
                                deleteRow(params.id);
                                if(baseURL==='Document'){
                                    const response = await Axios.get(
                                        `OTEntityTree/GetEntityID/${params.row.OTEntityTreeID}`,
                                        { headers: { Authorization: "Bearer " + token } }
                                    )
                                    
                                    if(response !== null)
                                        dispatch(updateSpecificNodeEntityTree(response.data))
                                }
                            }}
                            label={t("common.Delete")}
                            disabled={editing || isLocalLoading}
                        />
                    )}
                </>,
            ];
        }
        return [];
    };

    const defaultActionsColumn = [
        {
            field: "actions",
            headerName: t("common.Actions"),
            type: "actions",
            width: 200,
            hide: hideActionColumn, // if changes to columnVisibilityModel, make sure not any important action is hidden (if column is completely hidden, the specific user won't see anything)
            cellClassName: "actions",
            sortable: false,
            getActions: params => [
                ...specificActionsColumns(params, editing, isLocalLoading),
                ...editActions(params),
            ],
        },
    ];

    const SelectAllCheckBoxHeader = ({ ...params }) => {
        const [isChecked, setIsChecked] = useState(false);
        return (
            <GridHeaderCheckbox
                {...params}
                checked={isChecked}
                onChange={async e => {
                    if (e.target.checked) {
                        dispatch(isFetching(true));
                        const allIds = await fetchData(
                            baseURL + "/allIDs",
                            token,
                            "List",
                            null,
                            paramURL,
                            searchText
                        );
                        onSelectionModelChange(allIds.data);
                        dispatch(isFetching(false));
                    } else {
                        onSelectionModelChange([]);
                    }
                    setIsChecked(x => !x);
                }}
            />
        );
    };

    /** Data grid fetching function, trigerred on the useEffect by the baseURL, locale or location. A basic query and token is given along the baseURL. Sometimes, paramURL is given to specify further the REST call. DOC*/
    const fetching = async isSubscribed => {
        setRequestsStartedCount(rs => (rs += 1));
        dispatch(isFetching(true));
        let query;
        query = {
            pageSize: pageSize,
            pageNumber: page,
            search: searchText,
            orderBy: sortModel?.[0]?.field,
            orderDirection: sortModel?.[0]?.sort,
        };
        const gridFetched = await fetchDataGrid(
            token,
            query,
            baseURL,
            `${paramURL}`,
            bodyOfRequest
        );
        
        if (isSubscribed) {
            setRequestsFinalized(rf => [
                ...rf,
                { ...gridFetched, requestsStartedCount, searchText },
            ]);
        }
        setIncrementedRow(null);
        if (editing) {
            setEditing(false);
        }
        const excelQuery = `&Search=${searchText}&orderBy=${
            sortModel?.[0]?.field ?? ""
        }&dir=${sortModel?.[0]?.sort ?? ""}`;
        setExcelRequest(excelQuery);
    };

    /**  */
    useUpdateEffect(() => {
        if (
            isArrayLength(requestsFinalized) &&
            requestsStartedCount === requestsFinalized.length
        ) {
            const latestRequest = requestsFinalized.find(
                e => e.requestsStartedCount === requestsStartedCount - 1
            );
            const finalCorrectRequest = Boolean(latestRequest)
                ? latestRequest
                : requestsFinalized[requestsFinalized.length - 1];
            setRows(finalCorrectRequest.data);
            setRowCount(finalCorrectRequest.totalCount);
            setIsLocalLoading(false);
            setIsLoading(false);
            dispatch(isFetching(false));
            setRequestsStartedCount(0);
            setRequestsFinalized([]);
        }
        // eslint-disable-next-line
    }, [requestsStartedCount, requestsFinalized]);

    /**  */
    useEffect(() => {
        let isSubscribed = true;
        if (GenericGridType !== currentGenericGridType || !baseURL) {
            setIsLoading(false);
            setIsLocalLoading(false);
            return null;
        } else {
            setIsLoading(true);
            fetching(isSubscribed);
        }
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, [baseURL, locale, location]);

    useUpdateEffect(() => {
        let isSubscribed = true;
        setIsLocalLoading(true);
        fetching(isSubscribed);
        return () => (isSubscribed = false);
    }, [
        sortModel,
        searchText,
        page,
        pageSize,
        paramURL,
        bodyOfRequest,
        forceGridRefresh,
    ]);

    /* Updating current row, for highlight purpose. */

    const currentLocation = location.pathname;
    function checkingRowToHighlight(rowID) {
        for (const [key, value] of Object.entries(HighlightedRows)) {
            if (key === currentLocation && !isAdvancedSelectorGrid) {
                if (rowID === value) {
                    return `highlightCurrentRow`;
                } else {
                    return `rowDefaultStyle`;
                }
            }
        }
    }

    useEffect(() => {
        if (visitedRow && isStandalone) {
            dispatch(
                updateHighlightedRows({
                    CurrentLocation: currentLocation,
                    VisitedRowID: visitedRow,
                })
            );
        }
    }, [visitedRow, isStandalone, currentLocation, dispatch]);

    /* Edit row handling */
    // Standalone methods (directly adressable to DataGridPro)
    const handleRowEditStart = (_params, event) => {
        event.defaultMuiPrevented = true;
    };
    const handleRowEditStop = async (params, event) => {
        event.stopPropagation();
        event.defaultMuiPrevented = true;
        if (event.code !== "Enter") handleCancelClick(params.id)(event);
    };
    const handleEditClick = id => event => {
        event.stopPropagation();
        setEditing(true);

        if (isStandalone) {
            dispatch(
                updateHighlightedRows({
                    CurrentLocation: currentLocation,
                    VisitedRowID: id,
                })
            );
        }

        apiRef?.current.setRowMode(id, "edit");
    };
    const handleCellFocusOut = (_params, event) => {
        event.defaultMuiPrevented = true;
    };
    const handleSaveClick = id => async event => {
        event.stopPropagation();
        const oldRow = apiRef?.current.getRow(id);
        apiRef?.current.setCellFocus(id, "actions");
        const isValid = await apiRef?.current?.commitRowChange(id);
        if (isValid) {
            handleRowEditCommit(id, oldRow);
        }
    };
    const handleCancelClick = ID => event => {
        event.stopPropagation();
        apiRef?.current.setRowMode(ID, "view");
        const row = apiRef?.current.getRow(ID);
        if (row.isNew) {
            apiRef?.current.updateRows([{ ID, _action: "delete" }]);
        } else {
            apiRef?.current.setEditRowsModel({});
        }
        setEditing(false);
    };

    // Standalone Hooks
    const deleteRow = useCallback(
        async id => {
            try {
                if (id !== "NEW") {
                    await deleteModel(id, token, baseURL);
                }
                apiRef?.current.updateRows([{ ID: id, _action: "delete" }]);
                onDelete();
            } catch (error) {
                console.error(error);
            }
        },
        [baseURL, token, apiRef, onDelete]
    );

    const handleRowEditCommit = useCallback(
        async (id, oldRow) => {
            const model = apiRef?.current?.getEditRowsModel();
            const newRow = model[id];
            const cleanedEditedRow = newRow
                ? Object.fromEntries(
                      Object.keys(newRow).map(e => {
                          return [e, newRow[e].value];
                      })
                  )
                : {};
            let result = { ...oldRow, ...cleanedEditedRow };
            try {
                result = await beforeCommitRow(result);
                const config = {
                    headers: { Authorization: "Bearer " + token },
                };
                let referenceName = baseURL;
                if(baseURL[0]==='/'){
                    referenceName = baseURL.substring(1);
                }

                if(referenceName.includes('ref_')
                    || referenceName.includes('Ref_')
                    || referenceName === 'Branch'
                    || referenceName === 'Country'
                    || referenceName === 'Family'
                    || referenceName === 'Region')
                {
                    let currentVersion = await Axios
                                            .get(`${referenceName}/getcurrentversion/${referenceName}`, config)
                                            .catch(error => {
                                                console.error(error)
                                            });
                    result["Version"] = currentVersion.data + 1;
                }
                if (result.isNew) {
                    delete result.isNew;
                    delete result.ID;
                    const response = await addNewModel(result, token, baseURL);
                    const newID = response.data.ObjectID;
                    apiRef?.current?.updateRows([
                        { ID: id, _action: "delete" },
                        { ID: newID, ...result },
                    ]);
                    apiRef?.current.setRowMode(newID, "view");
                    if (isStandalone) {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: newID,
                            })
                        );
                    }
                    setIncrementedRow(incrementedRow => incrementedRow + 1);
                } else {
                    const commitedRowID = result.ID;
                    await editModel(result, commitedRowID, token, baseURL);
                    apiRef?.current.setRowMode(commitedRowID, "view");
                }
                apiRef?.current.setEditRowsModel({});
                setEditing(false);
            } catch (error) {
                //restore previous state
                apiRef?.current?.updateRows([oldRow]);
                apiRef?.current?.setEditRowsModel({
                    [id]: {
                        ...newRow,
                    },
                });
                apiRef?.current?.setRowMode(id, "edit");
            }
        },
        // eslint-disable-next-line
        [apiRef, baseURL, dispatch, token]
    );

    function EditToolbar(props) {
        const { apiRef } = props;
        const handleClick = () => {
            // HANDLE CREATION BUG
            const ID = "NEW";
            apiRef?.current?.updateRows([{ ID, isNew: true }]);
            apiRef?.current?.setRowMode(ID, "edit");
            // Wait for the grid to render with the new row
            setTimeout(() => {
                setEditing(true);
                apiRef?.current?.setCellFocus(ID, cellFocusField);
            }, 150);
        };
        return (
            <GridToolbarContainer>
                {toolbarManagement.disableToolbar ? null : (
                    <Grid container sx={{ display: "inline-flex" }}>
                        {!toolbarManagement.toolbarFilteringToolsOnly &&
                            !isActionDisabled && (
                                <>
                                    {toolbarManagement.add && (
                                        <ButtonToolbar
                                            titleTooltip={t(
                                                "common.AddAnElement"
                                            )}
                                            title={t("common.Add")}
                                            startIcon={<GridAddIcon />}
                                            onClick={handleClick}
                                            disabled={editing}
                                        />
                                    )}
                                    {toolbarManagement.newAction}

                                    {(toolbarManagement.add ||
                                        toolbarManagement.newAction) && (
                                        <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ mx: 1 }}
                                            flexItem
                                        />
                                    )}
                                </>
                            )}

                        {!toolbarManagement.toolbarActionOnly && (
                            <>
                                <GridToolbarColumnsButton
                                    sx={{ color: "secondary.main" }}
                                />
                                <GridToolbarFilterButton
                                    sx={{ color: "secondary.main" }}
                                />
                                <GridToolbarDensitySelector
                                    sx={{ color: "secondary.main" }}
                                />
                                {!toolbarManagement.tools.disableExport && (
                                    <GridToolbarExport
                                        sx={{ color: "secondary.main" }}
                                    />
                                )}
                                {toolbarManagement.newFilter}
                            </>
                        )}
                    </Grid>
                )}
            </GridToolbarContainer>
        );
    }
    const columns = gridSpecificColumns && [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderHeader: SelectAllCheckBoxHeader,
        },
        ...gridSpecificColumns.concat(
            hideActionColumn ? [] : defaultActionsColumn
        ),
    ];

    const displayBackButton =
        withBackButton ||
        fileType ||
        (!isEmpty(gridConfig) && element !== "OTEntityID") ||
        location.state?.withBackButton;

    const onPageChange = page => {
        doOnPageChange(page);
        setPage(page);
    };

    return isLoading ? (
        specificScreenLoading ?? <ScreenLoading />
    ) : (
        <>
            {disableTitle ? null : (
                <Box
                    sx={{
                        typography: "h3",
                        pt: 6,
                        pl: 5,
                        pb: 2,
                        display: "flex",
                        alignItems: "baseline",
                    }}
                >
                    {inProgress ? (
                        <InProgress sx={{ width: 1 }}>{label}</InProgress>
                    ) : (
                        label
                    )}
                    {hint && (
                        <BasicTooltip title={hint}>
                            <span>
                                <IconButton disabled disableRipple>
                                    <InfoIcon />
                                </IconButton>
                            </span>
                        </BasicTooltip>
                    )}
                    {displayBackButton && <Grid item sx={{ flexGrow: 1 }} />}
                    {displayBackButton && (
                        <Box>
                            <BasicButton
                                size="small"
                                sx={{
                                    mr: 5,
                                }}
                                onClick={() => {
                                    setIsLoading(true);
                                    setGridConfig(null);
                                    history.goBack();
                                }}
                                startIcon={<ArrowBackIcon />}
                            >
                                {t("common.Back")}
                            </BasicButton>
                        </Box>
                    )}
                </Box>
            )}
            <PromptDialog
                editing={editing}
                message={t("dialog.PromptChangesHasOccured")}
            />
            {children ? (
                <Toolbar disableGutters>
                    <Grid container>{children}</Grid>
                </Toolbar>
            ) : null}
            {columns && rows ? ( //
                <Box
                    sx={{
                        paddingBottom: "40px",
                        "& .rowDefaultStyle": {
                            borderLeft: "4px solid transparent",
                            paddingLeft: 0,
                            marginLeft: "-4px",
                        },
                        "& .highlightCurrentRow": {
                            borderLeft: "4px solid #ED0000",
                            borderRadius: "8px",
                            marginRight: "-4px",
                        },
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                            {
                                display: displaySelectAllCheckbox,
                            },
                        ...boxStyle,
                    }}
                >
                    {!disableResearch && (
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <SearchInTable
                                disabled={editing}
                                searchText={searchText}
                                setSearchText={t => {
                                    setSearchText(t);
                                }}
                            />
                        </Box>
                    )}
                    <DataGridPro
                        autoHeight={true}
                        apiRef={apiRef}
                        columns={columns?.map((item, index) => {
                            return { sortable: !editing, ...item };
                        })}
                        components={{
                            ...components,
                            Toolbar: EditToolbar,
                        }}
                        componentsProps={{
                            toolbar: { apiRef },
                        }}
                        disableColumnFilter={
                            toolbarManagement.tools.disableColumnFilter
                        }
                        disableColumnSelector={
                            toolbarManagement.tools.disableColumnSelector
                        }
                        disableDensitySelector={
                            toolbarManagement.tools.disableDensitySelector
                        }
                        disableColumnMenu={true}
                        editMode="row"
                        experimentalFeatures={{
                            preventCommitWhileValidating: true,
                        }}
                        getRowId={row => {
                            /** In some specific situations (e.g. Reporting records, there's no ID provided, so a generated front uuid is provided to each row must have a unique identifier) */
                            return row?.ID ?? uuidv4();
                        }}
                        hideFooter={editing}
                        initialState={initialState}
                        isCellEditable={isCellEditable}
                        loading={isLocalLoading}
                        localeText={
                            dataGridLocalisation[locale].components.MuiDataGrid
                                .defaultProps.localeText
                        }
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onCellFocusOut={handleCellFocusOut}
                        getRowClassName={params =>
                            checkingRowToHighlight(
                                params.row[rowHighlightPropName]
                            )
                        }
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        pagination //{isStandalone ? false : true}
                        paginationMode="server"
                        page={page}
                        pageSize={pageSize + incrementedRow}
                        onPageSizeChange={newPageSize => {
                            setPageSize(newPageSize);
                        }}
                        onPageChange={n => {
                            onPageChange(n);
                        }}
                        onSortModelChange={newSortModel => {
                            setSortModel(newSortModel);
                        }}
                        rowCount={rowCount + incrementedRow}
                        rows={rows}
                        sortModel={sortModel}
                        sortingMode="server"
                        sx={!isStandalone ? { m: 0 } : {}}
                        isRowSelectable={params =>
                            isLoading || isLocalLoading
                                ? false
                                : rowSelectability(params)
                        }
                        keepNonExistentRowsSelected
                        onSelectionModelChange={onSelectionModelChange}
                        {...props}
                    />
                </Box>
            ) : (
                <ScreenLoading />
            )}
        </>
    );
};

export default DataGridComponent;

import Axios from "axios";
import { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as r from "../constants/routes";
import { tokenAuthenticated } from "../redux/actions/auth";

export const useHistoryState = (label, initValue) => {
    const [state, setState] = useState(
        window.history.state?.state &&
            Object.keys(window.history.state?.state).includes(label)
            ? window.history.state.state[label]
            : initValue
    );
    const updateState = (t, silent) => {
        if (!silent) {
            setState(t);
        }
        window.history.replaceState(
            {
                ...window.history.state,
                state: { ...window.history.state?.state, [label]: t },
            },
            ""
        );
    };

    return [state, (t, silent) => updateState(t, silent)];
};

export const useDebounceEffect = (callback, delay) => {
    const latestCallback = useRef();
    const latestTimeout = useRef();

    useEffect(() => {
        latestCallback.current = callback;
    }, [callback]);

    return () => {
        if (latestTimeout.current) {
            clearTimeout(latestTimeout.current);
        }
        latestTimeout.current = setTimeout(() => {
            if (latestCallback.current) latestCallback.current();
        }, delay);
    };
};

export const useAxiosConfig = () => {
    const token = useSelector(state => state.auth.Token);
    return useMemo(() => {
        return {
            headers: { Authorization: "Bearer " + token },
        };
    }, [token]);
};

export const useUpdateEffect = (effect, dependencies = []) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return effect();
        }
        // eslint-disable-next-line
    }, dependencies);
};

/** Custom hook called whatever the dependency is: {nextClientRoute} when user changes route, {onSubmitAction} when user is submitting a form or valid dialog form. Beware of sequence and side-effects. If everything is ok, put it also in StationGroupGrid(x), AuthRouter(v), edition panels (x) . */
export const useCheckAndRevalidateToken = (
    setSessionVerification,
    dependencies = []
) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector(state => state.auth.Token);
    const isConnected = useSelector(state => state.auth.IsConnected);
    let lastAuthentication = useSelector(state => state.auth.Since);
    const isFromEntraId = useSelector(state => state.auth.FromEntraId);

    useEffect(() => {
        let isSubscribed = true;
        const checkLocalToken = async () => {
            if (
                isConnected && 
                token &&
                history.location.pathname !== "/OTuser" &&
                lastAuthentication   
            ) {
                // eslint-disable-next-line
                lastAuthentication = lastAuthentication
                    ? new Date(lastAuthentication)
                    : new Date(2000, 1, 1);
                const numberHours =
                    (new Date().getTime() - lastAuthentication.getTime()) /
                    (1000 * 3600);
                if (numberHours <= 1) {
                    console.log("Recent authentication found.");
                } else {
                    /* Occurs when location changes on browser changes: so if user wants to proceed to the next route or url changes (e.g. refreshing page for example, but mainly while 'history.push()' ) */
                    if (isFromEntraId) {
                        revalidation();
                    } else {
                        setSessionVerification(true);
                        dispatch(tokenAuthenticated(token));
                    }
                }
            }
        };
        if (isSubscribed) {
            checkLocalToken();
        }
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, dependencies);

    function revalidation() {
        /** 1 - Digital Pass : First step of to-go authentifiction with Digital Pass from Memority OAuth system. Get the redirection URL immediately when user is on LoginForm  */
        const fetching = async () => {
            try {
                const url = await Axios.get(`${r.oTUser}/EntraID`);
                if (url) {
                    const operation = async () => {
                        setSessionVerification(true);
                        await setTimeout(() => {
                            return window.location.replace(url.data);
                        }, 1500);
                    };
                    return operation();
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetching();
    }

    setTimeout(() => {
        setSessionVerification(false);
    }, 3000); // displays 3 seconds

    /* Usage exemple (while route changes) :     
    
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const nextClientRoute = useSelector(state => state.userPref.NextClientRoute)
    const [sessionVerification, setSessionVerification] = useState(false)
    useCheckAndRevalidateToken(setSessionVerification, [nextClientRoute])
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    
    */
};

export const useEditionRightsPerEntityAccess = (entityID, rights = []) => {
    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const hasRightPerEntityAccess = useHasRightPerEntityAccess(
        entityID,
        rights
    );

    if (CompanyManager || HoldingManager) {
        return true;
    }
    return hasRightPerEntityAccess;
};

export const useHasRightPerEntityAccess = (entityID, rights = []) => {
    const {
        BranchManagerEntityIDs,
        EntityManagerEntityIDs,
        ReaderEntityIDs,
        ExternalEntityIDs,
        ContributorEntityIDs,
    } = useSelector(state => state.auth);

    const auth = {
        BranchManager: BranchManagerEntityIDs,
        EntityManager: EntityManagerEntityIDs,
        Reader: ReaderEntityIDs,
        External: ExternalEntityIDs,
        Contributor: ContributorEntityIDs,
    };

    if (!entityID) {
        return false;
    }
    return rights.some(right => auth[right]?.includes(entityID));
};

export const useEditionRightsPerUserStatus = (rights = []) => {
    const { CompanyManager, HoldingManager, BranchManager, EntityManager, Contributor } =
        useSelector(state => state.auth);

    const auth = {
        BranchManager,
        EntityManager,
        Contributor,
    };

    if (CompanyManager || HoldingManager) {
        return true;
    }
    return rights.some(right => auth[right]);
};

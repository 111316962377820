import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Paper,
    Popper,
    MenuList,
    ClickAwayListener,
    MenuItem,
    ButtonBase,
    Avatar,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../js/redux/actions/auth";
import { t } from "react-i18nify";

const UserMenu = () => {
    const [open, setOpen] = useState(false);
    const prevOpen = useRef(open);
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const userName = useSelector(state => state.auth.UserName);
    const fromEntraId = useSelector(state => state.auth.FromEntraId);
    const token = useSelector(state => state.auth.Token);
    const handleToggle = () => {
        setOpen(previouslyOpen => !previouslyOpen);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Box sx={{ display: "flex", px: 1 }}>
            <ButtonBase
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Avatar
                    sx={{
                        width: 24,
                        height: 24,
                        bgcolor: theme => theme.palette.secondary.main,
                        color: "primary.light",
                    }}
                />
                <Box
                    sx={{
                        typography: "body2",
                        mx: 1 / 2,
                    }}
                >
                    {userName !== " " ? userName : t("misc.lambdaUser")}
                </Box>
            </ButtonBase>

            <Box sx={{ zIndex: 1500 }}>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    disablePortal
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <MenuItem
                                    onClick={() =>
                                        dispatch(logout(fromEntraId, token))
                                    }
                                >
                                    <ExitToAppIcon />
                                    {t("placeholder.Logout")}
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Box>
        </Box>
    );
};
export default UserMenu;

import * as actionTypes from "../actionTypes";
import Axios from "axios";
import VersionJSON from "../../../common/sharedSettings.json";
import { setLocale } from "../../../js/redux/actions/i18n";
import { initializePref } from "./userPref";
import * as r from "../../constants/routes";
const version = VersionJSON.Version;

export const authenticated = (
    IsConnected,
    Token,
    FromEntraId,
    State,
    EntityManager,
    GisManager,
    CompanyManager,
    HoldingManager,
    BranchManager,
    Contributor,
    Reader,
    BranchManagerEntityIDs,
    EntityManagerEntityIDs,
    ReaderEntityIDs,
    ExternalEntityIDs,
    ContributorEntityIDs,
    UserID,
    UserName,
    External,
    DisplayNewFeaturesPopUp,
    ExternalProvidersToken
) => ({
    type: actionTypes.AUTHENTICATED,
    payload: {
        IsConnected,
        Token,
        FromEntraId,
        State,
        EntityManager,
        GisManager,
        CompanyManager,
        HoldingManager,
        BranchManager,
        Contributor,
        Reader,
        BranchManagerEntityIDs,
        EntityManagerEntityIDs,
        ReaderEntityIDs,
        ExternalEntityIDs,
        ContributorEntityIDs,
        UserID,
        UserName,
        External,
        DisplayNewFeaturesPopUp,
        ExternalProvidersToken
    },
});

export const unauthenticated = () => ({
    type: actionTypes.UNAUTHENTICATED,
});

export const hideNewFeaturesPopUp = () => ({
    type: actionTypes.HIDENEWFEATURESPOPUP,
});

export const logout = (fromEntraId = false, token = "") => {
    return dispatch => {
        // if (fromEntraId) {
        //     try {
        //         const config = {
        //             headers: { Authorization: "Bearer " + token },
        //         };
        //         var res = await Axios.get(
        //             `${r.oTUser}/LogoutEntraId`,
        //             config
        //         );
        //         console.log(res);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        dispatch(unauthenticated());
    };
};
export const authentication_error = message => ({
    type: actionTypes.AUTHENTICATION_ERROR,
    payload: message,
});

export const unhelpfultoken = () => ({ type: actionTypes.UNHELPFULTOKEN });
export const entered = route => ({
    type: actionTypes.ENTERED,
    payload: route,
});
export const reseterror = () => ({ type: actionTypes.RESETERROR });

const batchDispatch = (response, dispatch) => {
    if (response.data.IsConnected === true) {
        dispatch(
            authenticated(
                response.data.IsConnected,
                response.data.Token,
                response.data.FromEntraId,
                response.data.State,
                response.data.EntityManager,
                response.data.GisManager,
                response.data.CompanyManager,
                response.data.HoldingManager,
                response.data.BranchManager,
                response.data.Contributor,
                response.data.Reader,
                response.data.BranchManagerEntityIDs,
                response.data.EntityManagerEntityIDs,
                response.data.ReaderEntityIDs,
                response.data.ExternalEntityIDs,
                response.data.ContributorEntityIDs,
                response.data.UserID,
                response.data.UserName,
                response.data.External,
                response.data.DisplayNewFeaturesPopUp,
                response.data.ExternalProvidersToken
            )
        );
        dispatch(setLocale(response.data.Language));
        dispatch(
            initializePref(
                response.data.MapPrefs
                    ? JSON.parse(response.data.MapPrefs)
                    : {},
                response.data.BookmarkPrefs
            )
        );
    } else {
        dispatch(authentication_error("Invalid username or password!"));
    }
};

/** 4 - Digital Pass : with received "code", a POST post occurs on OT backend to get user data to dispatch on reducer, then getting token from redux state to further authenfication.  */
export const authorizeEntraId = (code, id_token) => {
    return async dispatch => {
        try {
            const response = await Axios.post(
                `${r.oTUser}/IdentifyEntraID`,
                { code: code, id_token: id_token }
            );
            batchDispatch(response, dispatch);
        } catch (error) {
            console.log("Error:  ", error);
            dispatch(authentication_error("Invalid username or password!"));
        }
    };
};

/** Soon deprecated : It was a custom token authentification system.  */
export const authorize = (email, password) => {
    return async dispatch => {
        try {
            const response = await Axios.post(`${r.oTUser}/identify`, {
                IGG: email,
                Password: password,
            });
            batchDispatch(response, dispatch);
        } catch (error) {
            dispatch(authentication_error("Invalid username or password!"));
        }
    };
};

export const entry = route => {
    return dispatch => {
        dispatch(entered(route));
    };
};

/** Soon deprecated : It was a custom token authentification system.  */
export const tokenAuthenticated = token => {
    return async dispatch => {
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        try {
            const response = await Axios.get(`${r.oTUser}/validate`, config);
            if (response.data.IsConnected === true) {
                dispatch(
                    authenticated(
                        response.data.IsConnected,
                        response.data.Token,
                        response.data.FromEntraId,
                        response.data.State,
                        response.data.EntityManager,
                        response.data.GisManager,
                        response.data.CompanyManager,
                        response.data.HoldingManager,
                        response.data.BranchManager,
                        response.data.Contributor,
                        response.data.Reader,
                        response.data.BranchManagerEntityIDs,
                        response.data.EntityManagerEntityIDs,
                        response.data.ReaderEntityIDs,
                        response.data.ExternalEntityIDs,
                        response.data.ContributorEntityIDs,
                        response.data.UserID,
                        response.data.UserName,
                        response.data.External,
                        response.data.DisplayNewFeaturesPopUp,
                        response.data.ExternalProvidersToken
                    )
                );
            } else {
                dispatch(unauthenticated());
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(unauthenticated());
            } else {
                dispatch(unhelpfultoken());
            }
        }
    };
};

export const updateEntitiesList = (entitiesList) => dispatch => {
    dispatch({
        type: actionTypes.UPDATEENTITIESLIST,
        payload: entitiesList
    })
};

export const loadVersion = () => ({
    type: actionTypes.VERSIONCHANGE,
    payload: version,
});
